.standard-page {
  margin-left: 300px;
  margin-bottom: 40px;
  padding-top: 120px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 8px;
}

@media (max-width: 640px) {
  .standard-page {
    margin-left: 10px;
  }
}
