.date-picker-container {
  position: relative;
}

.clock-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.transparent-background {
  background-color: transparent !important;
}
