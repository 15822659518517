.app-navbar {
  background-color: #282c34;
  padding: 12px;
  color: white;
  text-align: left;
}

.flex-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.link {
  cursor: pointer;
}

.nav-link {
  color: #9797a4;
  font-size: 14px;
  cursor: pointer;
}

.selected {
  background-color: #e3e3ea;
  color: #222325;
}
